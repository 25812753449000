import React from 'react'
import { SectionTitle } from './SectionTitle'

export const CareerSection = () => {
  return (
    <>
      <section
        id='careerSection'
        className='cta-subscribe ptb-120 position-relative overflow-hidden'
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                subtitle='Career'
                title='Want To Join Us?'
                description='Be a Part of Our Success Story'
                centerAlign
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10'>
              <div className='subscribe-info-wrap text-center position-relative z-2'>

                <p>
                  At our company, we believe that our employees are our greatest asset. We strive to create a dynamic and inclusive work environment that encourages creativity, innovation, and growth. As we continue to expand, we are always looking for talented individuals who share our passion and dedication.
                </p>
                <p>
                  Please share your Resume on our email.
                </p>
                <div>
                  <a href='mailto:hr@goorbit.in'>hr@goorbit.in</a>
                </div>
              </div>
            </div>
          </div>
          <div
            className='
              bg-circle
              rounded-circle
              circle-shape-3
              position-absolute
              bg-dark-light
              right-5
            '
          ></div>
          <div
            className='
              bg-circle
              rounded-circle
              circle-shape-1
              position-absolute
              bg-warning
              left-5
            '
          ></div>
        </div>
      </section>
    </>
  )
}
